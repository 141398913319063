@import "../../../../assets/scss/theme.scss";

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }
.apex-charts {
  width: 120px;
  margin-top: -20px;
  margin-bottom: 20px;
}
